<template>
  <div>
    <NotificationListManager />
    <ui-modal
      :header="$t('views.link_business_profile.title')"
      open
    >
      <div slot="body">
        <h2>
          {{ $t('views.link_business_profile.welcome', {name: userData.getEmail()}) }}
        </h2>
        <p>
          {{ $t('views.link_business_profile.text') }}
        </p>
      </div>

      <div slot="footer">
        <div class="d-flex align-items-center justify-content-end">
          <ui-button
            :face="FACES.text"
            :size="SIZES.large"
            @clickbutton="onClickDontLinkProfile"
          >
            {{ $t('views.link_business_profile.dont_link') }}
          </ui-button>

          <ui-button
            :size="SIZES.large"
            @clickbutton="onClickLinkProfile"
          >
            {{ $t('views.link_business_profile.link_accounts') }}
          </ui-button>
        </div>
      </div>
    </ui-modal>
  </div>
</template>

<script>
import { navigationErrorHandler } from '@emobg/web-utils';
import EventHandlerMixin from '@/mixins/EventHandler';
import LoaderMixin from '@/mixins/Loader';
import UserDataMixin from '@/mixins/UserData';
import BookingRoutesNames from '@Bookings/router/routes-names';
import { useAuth } from '@/composable/Api/Auth/auth';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import NotificationListManager from '@/components/NotificationList/NotificationListManager';

import { useUserData } from '@/composable/User/useUserData';

export default {
  name: 'LinkBusinessProfile',
  components: {
    NotificationListManager,
  },

  mixins: [
    EventHandlerMixin,
    LoaderMixin,
    UserDataMixin,
  ],

  setup() {
    const { idpUuid } = useAuth();
    const { notifyError } = useNotifications();
    const { userData } = useUserData();

    return {
      idpUuid,
      notifyError,
      userData,
    };
  },

  created() {
    this.toggleLoaderStatus(false);
  },

  methods: {
    onClickDontLinkProfile() {
      this.$router.push({ name: BookingRoutesNames.home }).catch(navigationErrorHandler);
    },

    onClickLinkProfile() {
      this.setFromIDPSignUp(this.idpUuid);

      const invitationToken = this.$cookie.get('invitationToken');
      const invitationUUID = this.$cookie.get('invitationUUID');

      if (!(invitationToken && invitationUUID)) {
        this.notifyError({
          text: this.$t('notifications.whooops'),
          textAction: 'Ok',
        });
        this.$router.replace({ name: 'SignUp' }).catch(navigationErrorHandler);
      }

      this.$cookie.delete('invitationToken');
      this.$cookie.delete('invitationUUID');

      this.$router.replace(
        {
          name: 'CompleteInvitation',
          params: {
            invitationToken,
            invitationUUID,
          },
        },
      ).catch(navigationErrorHandler);
    },
  },
};

</script>
